<template>
	<section v-if="good">
		<div class="detailBox">
			<div class="imgBox">
				<ul>
					<li>
						<img :src="good.img" />
					</li>
				</ul>
			</div>
			<div class="infoBox">
				<p class="stitle">HONGKONG HAPPY GAMES</p>
				<h1>{{good.title}}</h1>
				<div class="priceBox">
					<p class="price">
						<span class="original">${{good.originalPrice}} USD</span>
						<span class="onsale">${{good.presentPrice}} USD</span>
						<!-- <span class="tag">강시</span> -->
					</p>
				</div>
				<div>
					<el-form
					    label-position="top"
					    label-width="100px"
					    :model="form"
					    style="max-width: 460px"
					  >
					    <el-form-item label="수량">
							<el-input-number v-model="form.num" :min="1" :max="good.maxNum" size="large" />
					    </el-form-item>
					  </el-form>
				</div>
				<div class="btns">
					<el-button @click="addCart">카트에 추가</el-button>
					<!-- <el-button type="primary" :disabled="true">지금 구매</el-button> -->
				</div>
				<div class="description" v-html="good.details">
				</div>
				<div class="accordion">
					<el-collapse v-model="activeName" accordion>
					  <el-collapse-item name="1">
						  <template #title>
							  <el-icon size="14" style="margin-right: 5px;"><Van /></el-icon>
							배송 및 반품
						  </template>
						<div>
						  귀하께서 구입 후 귀하의 이메일로 바로 발송해 드립니다
						</div>
					  </el-collapse-item>
					</el-collapse>
				</div>
			</div>
		</div>
	</section>
	<section>
		<div class="multiBox">
			<h3>배송료 면제</h3>
			<p>귀하께서 구입 후 이메일로 직접 발송되는 경우 배송료 없음</p>
		</div>
	</section>
	<section class="bg1">
		  <div class="listBox">
			  <h2 class="title" style="font-size: 2.4rem;">귀하가 좋아할수도 있습니다다</h2>
			  <div class="listItem">
				  <ul>
					<template v-for="(item,index) in allList" :key="index">
						<li class="item">
							<div class="imgBox">
								<img :src="item.img" />
								<!-- <span class="tag">강시</span> -->
							</div>
							<div class="desBox">
								<h3 class="title"><router-link :to="'/products/detail/'+item.id">{{item.title}}</router-link></h3>
								<p class="price">
									<span class="original">${{item.originalPrice}} USD</span>
									<span class="onsale">${{item.presentPrice}} USD</span>
								</p>
							</div>
						</li>
					</template>
				  </ul>
			  </div>
		  </div>
	</section>
</template>

<script setup>
	import { Van } from '@element-plus/icons-vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { ref, onMounted } from "vue";
	import { useRouter,useRoute } from 'vue-router'
	import { getGoodList,addCartGood } from '@/api/index'
	import useUserStore from '@/store/modules/user'
	
	let router = useRouter();
	let route = useRoute();
	
	const allList = ref([])
	const goodList = ref([])
	const form = ref({
		num: 1
	})
	const good = ref({})
	const activeName = ref('1')
	onMounted(()=>{
		getGoodList({ id: route.params.Id }).then((res)=>{
			goodList.value = res.rows
			if(goodList.value.length > 0){
				good.value = goodList.value[0]
			}
		})
		getAll()
	})
	const getAll = () => {
		getGoodList().then((res)=>{
			allList.value = res.rows
		})
	}
	const addCart = () => {
		const { id, state, presentPrice } = good.value
		form.value.goodsId = id
		form.value.state = state
		form.value.totalPrice = (presentPrice * form.value.num).toFixed(2)
		form.value.unitPrice = presentPrice
		form.value.userId = useUserStore().userid
		addCartGood(form.value).then((res) => {
			ElMessage({
			    message: 'Success',
			    type: 'success',
			})
			router.push('/cart')
			console.log(res)
		})
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.detailBox{
	display: flex;max-width: 120rem;justify-content: center;
	margin: 0 auto;
	padding-top: 36px;padding-bottom: 12px;
	.imgBox{
		max-width: 65%;
		ul{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 2rem;
			padding: 0;
			grid-gap: 8px;
			li{
				width: calc(50% - 4px);font-size: 0;
				&:first-child{
					width: 100%;
				}
				img{
					width: 100%;
				}
			}
		}
	}
	.infoBox{
		padding: 0 0 0 4rem;box-sizing: border-box;
		max-width: 35%;font-size: 1.6rem;letter-spacing: 0.06rem;
		.stitle{
			font-size: 1rem;letter-spacing: 0.13rem;
		}
		.priceBox{
			margin: 1.5rem 0;
			.price{
				line-height: 24px;margin-top: 1rem;
				display: inline-flex;align-items: center;
				.original{
					display: inline-block;
					text-decoration: line-through;
					font-size: 1.5rem;
					color: rgba(18,18,18,0.75);margin-right: 1rem;
				}
				.onsale{
					color: rgb(18,18,18);
					font-size: 1.8rem;letter-spacing: .1rem;
				}
				.tag{
					padding: 0.05rem 1.2rem 0.06rem;margin-left: 1rem;
					display: inline-block;background: #0066CC;border-radius: 4rem;
					color: #fff;font-size: 1.2rem;
				}
			}
		}
		h1{
			font-size: 4rem;font-weight: 400;margin: 0;
		}
		.btns{
			width: 100%;
			.el-button{
				width: 100%;height: 4.5rem;border-radius: 0;
			}
			.el-button + .el-button{
				margin-left: 0;margin-top: 1rem;
			}
		}
		.description{
			margin: 2.5rem 0;
			line-height: calc(1 + 0.8 / 1.0);
			span{
				color: #a91313;
			}
		}
	}
}
.multiBox{
	max-width: 72rem;margin: 0 auto;
	padding: 36px 1.5rem 52px;font-size: 1.8rem;
	h3{
		font-weight: 400;margin: 0;
	}
	p{
		margin-top: 1rem;color: rgba(18,18,18,0.75);
		font-size: 1.6rem;
	}
}
</style>
